import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PluginAuthSuccess.css'; // Reuse the login.css for consistent styling
import './login.css';

const PluginAuthSuccess = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/'); // Redirect to the root path
  //   }, 3000); // Redirect after 3 seconds
  
  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, [navigate]);

  return (
    <div className="Login">
      <div className="secondaryContainer">
        <div className="logoBlack"></div>
      </div>
      <div className="centerContainer">
        <div className="authSuccessContainer">
          <h1 className="loginTitle">Authentication Successful!</h1>
          <p className="loginText">You can now return to Blender</p>
        </div>
      </div>
      <div className="secondaryContainer"></div>
    </div>
  );
};

export default PluginAuthSuccess;