import React, { useState, useRef, useEffect } from 'react';
import './DropdownMenu.css';

const DropdownMenu = ({
  presets, // Presets now come from the app component
  selectedItem, // The current selected item is managed by the app
  onPresetSelect,
  buttonStyle,
  onSavePreset, // If you need to save after edit
  onNameChange, // Callback for name change
  onDeletePreset, // Callback for deleting a preset
  currentUser,
  inspirationData,
  onAddPreset,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingName, setEditingName] = useState('');
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const [style, setStyle] = useState({});

  const handlePresetSelect = (item) => {
    onPresetSelect(item);
    setIsOpen(false); // Close the dropdown after selecting a preset
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const toggleDropdown = () => {
    // Do not toggle if in editing mode or if selectedItem is not valid.
    if (!isEditing && Array.isArray(presets)) {
      setIsOpen(!isOpen);
    }
  };

  const handleDoubleClick = () => {
    if (selectedItem && selectedItem.name) {
      setIsEditing(true);
      setEditingName(selectedItem.name);
    }
  };

  const handleChange = (e) => {
    setEditingName(e.target.value);
  };

  const handleBlur = () => {
    // Trim the editingName to remove whitespace from both sides
    const trimmedName = editingName.trim();
  
    // Check if trimmedName is not empty
    if (trimmedName) {
      setIsEditing(false);
      onNameChange(trimmedName); // Only call onNameChange if there's a valid name
    } else {
      // If trimmedName is empty, just close editing without saving
      setIsEditing(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && dropdownContentRef.current && buttonRef.current &&
          !buttonRef.current.contains(e.target) && 
          !dropdownContentRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setStyle({
        position: 'absolute',
        fontSize: '12px',
        width: `${buttonRect.width - 20}px`, 
        // Adjust the top position to make the dropdown appear over the button
        top: `${buttonRect.top + window.scrollY + 0}px`,
        left: `${buttonRect.left + window.scrollX}px`,
      });
    }
  }, [isOpen]);

  // const buttonText = selectedItem && selectedItem.name ? selectedItem.name : "Empty";

  return (
    <div>
      <button
        ref={buttonRef}
        className="presetButton"
        style={buttonStyle}
        onDoubleClick={handleDoubleClick}
      >
         {!isEditing ? (
          <span className={isOpen ? "" : "textCursor"}>
          {selectedItem && selectedItem.name ? selectedItem.name : "No Preset"}
          </span>) : (
          <input
            ref={inputRef}
            className="editInput"
            value={editingName}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            maxLength={17}
          />
        )}
        <span className="iconContainer">
        {selectedItem && (
          <>
            <span className="saveIcon"
              onClick={() => onSavePreset(currentUser.uid, selectedItem.id, selectedItem.name, inspirationData)}></span>
            <span className="deleteIcon" 
              style={{marginRight: '5px', marginBottom: '0.5px'}}
              onClick={() => onDeletePreset(selectedItem.id)}></span>
          </>
        )}
          <span className="expandIcon" onClick={toggleDropdown}></span>
        </span>

      </button>
      {isOpen && (
        <div className="dropdownContent" ref={dropdownContentRef} style={style}>
        <div className="dropdownItem noPresetItem" style={{marginBottom: '3px'}} onClick={() => handlePresetSelect(null)}>
          No Preset
        </div>
          {presets.map(item => (
            <div key={item.id} className="dropdownItem">
            <span onClick={() => handlePresetSelect(item)}>
                {item.name}
              </span>
              <div className="itemIconContainer">
                <span className="saveIcon" onClick={() => onSavePreset(currentUser.uid, item.id, item.name, inspirationData)}></span>
                <span className="deleteIcon" onClick={() => onDeletePreset(item.id)}></span>
              </div>
            </div>
          ))}
          {
            presets.length < 5 && (
              <div onClick={() => onAddPreset()} className='addIcon' style={{ paddingTop: '5px', width: '100%'}}>
              </div>
            )
          }

        </div>
      )}
    </div>
  );
};

export default DropdownMenu;