import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import './OpticSales.css';
import NavBar from './NavBar';
import Footer from './Footer';
import PricingPlans from './PricingPlan';
import ComparisonSlider from './ComparisonSlider';

import sketchCompAfter from './assets/sketch_comp_before.webp';
import sketchCompBefore from './assets/sketch_comp_after.webp';

import threeDCompAfter from './assets/3ddata_comp_before.webp';
import threeDCompBefore from './assets/3ddata_comp_after.webp';

import imageCompAfter from './assets/image_comp_before.webp';
import imageCompBefore from './assets/image_comp_after.webp';

import segCompAfter from './assets/seg_comp_before.webp';
import segCompBefore from './assets/seg_comp_after.webp';

import opticAutoplay from './assets/optic_autoplay.mp4';
import opticTrainAnything from './assets/train_anything.mp4';
import opticBlenderPlugin from './assets/blender_plugin.mp4';

function OpticSales() {
    const [isMonthly, setIsMonthly] = useState(true);
    
    // Refs for the videos
    const trainAnythingVideoRef = useRef(null);
    const blenderPluginVideoRef = useRef(null);

    const scrollToPricing = () => {
        const pricingSection = document.getElementById('pricing-section');
        if (pricingSection) {
            pricingSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0.5, // Trigger when 50% of the section is visible
        };

        // Callback for Intersection Observer
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Play the video when in view
                    if (entry.target.id === 'train-anything-section' && trainAnythingVideoRef.current) {
                        trainAnythingVideoRef.current.play();
                        trainAnythingVideoRef.current.muted = true; // Ensure muted for autoplay
                    }
                    if (entry.target.id === 'blender-plugin-section' && blenderPluginVideoRef.current) {
                        blenderPluginVideoRef.current.play();
                        blenderPluginVideoRef.current.muted = true; // Ensure muted for autoplay
                    }
                } else {
                    // Pause the video when out of view
                    if (entry.target.id === 'train-anything-section' && trainAnythingVideoRef.current) {
                        trainAnythingVideoRef.current.pause();
                    }
                    if (entry.target.id === 'blender-plugin-section' && blenderPluginVideoRef.current) {
                        blenderPluginVideoRef.current.pause();
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        const trainAnySection = document.getElementById('train-anything-section');
        const blenderPluginSection = document.getElementById('blender-plugin-section');

        if (trainAnySection) observer.observe(trainAnySection);
        if (blenderPluginSection) observer.observe(blenderPluginSection);

        // Cleanup on unmount
        return () => {
            if (trainAnySection) observer.unobserve(trainAnySection);
            if (blenderPluginSection) observer.unobserve(blenderPluginSection);
        };
    }, []);

    return (
        <div className="Home">
            <NavBar />
            <div className="videoContainerMain">
                <div className="videoBackgroundContent">
                    <video 
                        src={opticAutoplay} 
                        autoPlay 
                        loop 
                        muted 
                        playsInline 
                        className="autoplayVideo"
                    />
                    <button 
                        className="startNowButton"
                        onClick={scrollToPricing}
                        aria-label="Start Now and view Pricing Plans"
                    >
                        Start Now
                    </button>
                </div>
            </div>

            {/* Built For Intent Section */}
            <div className="homeContainerMain" style={{ marginBottom: '15px', marginTop: '-27px' }}>
                <div className="anyInputBackgroundContent">
                    <h1 className="boldText" style={{ fontSize: '50px', color: 'white'}}>
                        Built For Intent
                    </h1>
                    <h2 className="normalText" style={{ fontSize: '20px', margin: ' 10px 50px 25px 50px', color: 'white', textAlign: 'center', maxWidth: '600px'}}>
                        Use your preferred medium to express your ideas. Optic is built to handle them all.
                    </h2>
                    <div className="anyInputContainerContent">

                        <div className="anyInputContainer">
                            <ComparisonSlider
                                beforeImage={sketchCompBefore}
                                afterImage={sketchCompAfter}
                            />
                            <div>
                                <h1 className="boldText" style={{ fontSize: '20px', marginBottom: '10px', marginLeft: '28px', marginTop: '25px', color: 'white' }}>
                                    Sketch
                                </h1>
                            </div>
                        </div>

                        <div className="anyInputContainer">
                            <ComparisonSlider
                                beforeImage={threeDCompBefore}
                                afterImage={threeDCompAfter}
                            />
                            <div>
                                <h1 className="boldText" style={{ fontSize: '20px', marginBottom: '10px', marginLeft: '28px', marginTop: '25px', color: 'white' }}>
                                    3D Data
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="anyInputContainerContent">
                        <div className="anyInputContainer">
                            <ComparisonSlider
                                beforeImage={imageCompBefore}
                                afterImage={imageCompAfter}
                            />
                            <div>
                                <h1 className="boldText" style={{ fontSize: '20px', marginBottom: '10px', marginLeft: '28px', marginTop: '25px', color: 'white' }}>
                                    Image
                                </h1>
                            </div>
                        </div>

                        <div className="anyInputContainer">
                            <ComparisonSlider
                                beforeImage={segCompBefore}
                                afterImage={segCompAfter}
                            />
                            <div>
                                <h1 className="boldText" style={{ fontSize: '20px', marginBottom: '10px', marginLeft: '28px', marginTop: '25px', color: 'white' }}>
                                    Segmentation
                                </h1>
                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>

            {/* Partners Section */}
            <div className="partnersContainerThin" style={{ marginTop: '-32px' }}>
                <div className="partnersContainerThinContent">
                    <div className="featuresCardsContent">
                        <div className="featureCard">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="cloudIcon" style={{ marginBottom: '20px', marginTop: '40px' }}>
                                </div>
                                <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '5px', textAlign: 'center' }}>
                                    Cloud Based
                                </h1>
                                <h2 className="normalText" style={{ fontSize: '16px', textAlign: 'center', maxWidth: '250px' }}>
                                    To make it accessible, Optic is hosted in the cloud. You don't need powerful hardware or to install anything, you can just use it.
                                </h2>
                            </div>
                        </div>
                        <div className="featureCard">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="lockIcon" style={{ marginBottom: '20px', marginTop: '40px' }}>
                                </div>
                                <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '5px', textAlign: 'center' }}>
                                    Privacy
                                </h1>
                                <h2 className="normalText" style={{ fontSize: '16px', textAlign: 'center', maxWidth: '250px' }}>
                                    We don't train on any Pro user's data. None of the generations are public and no other user can access them.
                                </h2>
                            </div>
                        </div>
                        <div className="featureCard">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="subscriptionIcon" style={{ marginBottom: '20px', marginTop: '40px' }}>
                                </div>
                                <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '5px', textAlign: 'center' }}>
                                    Subscription
                                </h1>
                                <h2 className="normalText" style={{ fontSize: '16px', textAlign: 'center', maxWidth: '250px' }}>
                                    Optic is subscription based. Subscribe when you need it and cancel anytime. You can also try it out for 2 weeks for free.
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Train Anything Section */}
            <div id="train-anything-section" className="homeContainerMain" style={{ marginTop: '-17px' }}>
                <div className="trainAnythingBackgroundContent">
                    <h1 className="boldText" style={{ fontSize: '50px', color: 'white'}}>
                        Train Anything
                    </h1>
                    <h2 className="normalText" style={{ fontSize: '20px', margin: '10px 50px', color: 'white', textAlign: 'center', maxWidth: '700px'}}>
                        Thanks to Lenses, you can train any new concept for your generations. It's private and you can do it in one click. No labelling. No data preparation.
                    </h2>
                    <div className="videoContentTrainAnything" style={{ marginTop: '25px'}}>
                        <video 
                            ref={trainAnythingVideoRef}
                            src={opticTrainAnything} 
                            // Removed autoPlay
                            loop 
                            muted 
                            playsInline 
                            className="autoplayTrainAnythingVideo"
                        />
                    </div>
                </div>
            </div>

            {/* Blender Plugin Section */}
            <div id="blender-plugin-section" className="homeContainerMain" style={{ marginTop: '-27px' }}>
                <div className="blenderPluginBackgroundContent">
                    <h1 className="boldText" style={{ fontSize: '50px', color: 'white'}}>
                        Blender Plugin
                    </h1>
                    <h2 className="normalText" style={{ fontSize: '20px', margin: '10px 50px', color: 'white', textAlign: 'center', maxWidth: '700px'}}>
                        Iterate on your design directly from Blender by using the Optic plugin.
                    </h2>
                    <div className="videoContentTrainAnything" style={{ marginTop: '25px'}}>
                        <video 
                            ref={blenderPluginVideoRef}
                            src={opticBlenderPlugin} 
                            // Removed autoPlay
                            loop 
                            muted 
                            playsInline 
                            className="autoplayTrainAnythingVideo"
                        />
                    </div>
                </div>
            </div>

            <div className="partnersContainerThin" style={{ marginTop: '-17px' }}>
                <div className="partnersContainerThinContent">
                    <h1 className="mediumText" style={{ fontSize: '28px' }}>
                        Trusted by our partners
                    </h1>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '80%', justifyContent: 'space-between' }}>
                        {/* <div className="toyotaLogo">
                        </div> */}
                        <div className="PolestarLogo">
                        </div>
                        <div className="GACLogo">
                        </div>
                        <div className="VinfastLogo">
                        </div>
                    </div>
                </div>
            </div>

            {/* Pricing Section */}
            <div id="pricing-section" className="homeTitleContainer">
                <div className="homeTitle">Pricing</div>
            </div>

            <PricingPlans isMonthly={isMonthly} setIsMonthly={setIsMonthly} />

            <Footer />
        </div>
    );
}

export default OpticSales;