import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Home.css';
import NavBar from './NavBar';
import Footer from './Footer';

function Home() {
  return (
    <div className="Home">
      <Helmet>
        <title>UDIN</title>
        <meta
          name="description"
          content="Creators of Optic AI Design Tool. We provide creative tools for the design industry. Try out Optic today!"
        />
      </Helmet>
      <NavBar />
      <div className="homeContainerMain">
        <div className="homeContainerMainContent">
          <h1 className="boldText" style={{ fontSize: '64px', color: 'white', marginBottom: '10px', marginTop: '10px' }}>
            Optic
          </h1>
          <h2 className="mediumText" style={{ fontSize: '24px', color: 'white', marginBottom: '15px' }}>
            The AI Design Tool
          </h2>
          <Link to="/optic-tool">
            <button className="genericHomeButton">Learn More</button>
          </Link>
        </div>
      </div>

      <div className="homeContainerMain" style={{ marginTop: '-6px' }}>
        <div className="homeContainerCardsContent">
          <Link to="/optic-tool" className="homeContainerCard card-features">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                Features
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                Discover what you can do with Optic
              </h2>
            </div>
          </Link>

          <Link to="/pricing" className="homeContainerCard card-pricing">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                Pricing
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                Learn about our pricing and free trial
              </h2>
            </div>
          </Link>

          <Link to="/gallery" className="homeContainerCard card-gallery">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                Gallery
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                See what people are creating with Optic
              </h2>
            </div>
          </Link>
        </div>
      </div>

      <div className="homeTitleContainer">
        <div className="homeTitle">Company</div>
      </div>

      <div className="homeContainerMain">
        <div className="homeContainerCardsContent">
          <Link to="/tools" className="homeContainerCard card-tools">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                Tools
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                Check out all of our creative tools
              </h2>
            </div>
          </Link>

          <Link to="/about-us" className="homeContainerCard card-about">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                About Us
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                Learn about our team and mission
              </h2>
            </div>
          </Link>

          <Link to="/contact" className="homeContainerCard card-contact">
            <div style={{ marginTop: '100px' }}></div>
            <div>
              <h1 className="boldText" style={{ fontSize: '28px', color: 'white', marginBottom: '5px', marginLeft: '28px' }}>
                Contact
              </h1>
              <h2
                className="mediumText"
                style={{ fontSize: '16px', color: 'white', marginBottom: '25px', marginLeft: '28px', marginRight: '28px' }}
              >
                Get in touch with us
              </h2>
            </div>
          </Link>
        </div>
      </div>

      <div className="homeContainerThin" style={{ marginTop: '2px' }}>
        <div className="homeContainerThinContent">
          <h1 className="mediumText" style={{ fontSize: '48px', color: 'white', marginBottom: '15px' }}>
            Try Optic Now
          </h1>
          <Link to="/pricing">
            <button className="genericHomeButton" style={{ width: '150px', fontSize: '16px' }}>
              Free Trial
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;