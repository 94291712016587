import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import RequireAuth from './RequireAuth';
import Login from './login';
import Home from './Home';
import TermsAndConditions from './TermsAndConditions';
import Tool from './Tool';
import BlenderAuth from './BlenderAuth';
import Pricing from './Pricing';
import About from './About';
import OpticSales from './OpticSales';
import Gallery from './Gallery';
import ToolsInfo from './ToolsInfo';
import BlenderShaders from './BlenderShaders';
import Contact from './Contact';
import PluginAuth from './PluginAuth';
import PluginAuthSuccess from './PluginAuthSuccess';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/optic"
            element={
              <RequireAuth>
                <Tool />
              </RequireAuth>
            }
          />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms-of-service" element={<TermsAndConditions />} />
          <Route path="/plugin-auth" element={<PluginAuth />} />
          <Route path="/plugin-auth-success" element={<PluginAuthSuccess />} />
          <Route path="/blender-auth" element={<BlenderAuth />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/optic-tool" element={<OpticSales />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/tools" element={<ToolsInfo />} />
          <Route path="/blender-shaders" element={<BlenderShaders />} />
          <Route path="/contact" element={<Contact />} />
          {/* Other routes can go here */}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;



