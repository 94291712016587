import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from './firebase-config';

const PluginAuth = () => {
  const navigate = useNavigate();
  const db = getFirestore();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (!code) {
      navigate('/'); // Redirect if no code
      return;
    }

    const handleAuthentication = async () => {
      if (!auth.currentUser) {
        // Prompt user to sign in
        await signInWithPopup(auth, googleProvider);
      }

      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const refreshToken = user.refreshToken;

        // Save the token and refresh token associated with the code
        await setDoc(doc(db, 'pluginAuth', code), {
          token,
          refreshToken,
          timestamp: Date.now(),
        });

        setIsAuthenticated(true);
        navigate('/plugin-auth-success');
      }
    };

    if (!isAuthenticated) {
      handleAuthentication();
    }
  }, [navigate, isAuthenticated]);

  return <div>Please wait, authenticating...</div>;
};

export default PluginAuth;