import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import './ToolsInfo.css';
import './BlenderShaders.css';
import NavBar from './NavBar'; // Import NavBar component
import Footer from './Footer'; // Import Footer component

function BlenderShaders() {
  return (
    <div className="Home">
      <Helmet>
        <title>UDIN Blender Shaders</title>
        <meta
          name="description"
          content="The essential Blender shader collection for designers"
        />

        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Blender Shaders - UDIN" />
        <meta
          property="og:description"
          content="The essential Blender shader collection for designers"
        />
        <meta
          property="og:image"
          content="./assets/Shader1.jpg"
        />
        <meta
          property="og:url"
          content="https://udinbv.com/shaders"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Blender Shaders - UDIN" />
        <meta
          name="twitter:description"
          content="The essential Blender shader collection for designers"
        />
        <meta
          name="twitter:image"
          content="./assets/Shader1.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <NavBar /> {/* Use NavBar component */}
      <div className="homeContainerMain">
        <div className="blenderMainContent">
          <h1
            className="boldText"
            style={{
              fontSize: '64px',
              color: 'white',
              marginBottom: '10px',
              marginTop: '20px',
            }}
          >
            Shaders
          </h1>
          <a href="https://udinbv.gumroad.com/l/shaders">
            <button
              className="toolsButton"
              style={{ padding: '10px 20px', fontSize: '16px' }}
              aria-label="Download Blender Shaders for Free"
            >
              Download For Free
            </button>
          </a>
        </div>
      </div>

      <div className="homeContainerMain">
        <div className="faqContainerCardsContent">
          <div className="shaderCard shadersQuality">
            {/* If you have an image, include it here with alt text for SEO and accessibility */}
            {/* Example:
            <img src="/images/shader-quality.jpg" alt="Unlock Quality with Our Shaders" className="shaderImage" />
            */}
          </div>

          <div className="toolInfoCard">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                height: '100%',
                marginBottom: '45px',
              }}
            >
              <h2
                className="boldText"
                style={{
                  fontSize: '28px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '30px',
                }}
              >
                Unlock Quality
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Handcrafted by designers.</strong> The shaders were built
                for maximum efficiency in design visualization work.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Simulating reality</strong> through mathematical
                representation of complex light behaviors.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Limitless possibilities.</strong> Each individual shader
                offers a high level of control and customization.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="homeContainerMain" style={{ marginTop: '15px' }}>
        <div className="faqContainerCardsContent">
          <div className="toolInfoCard">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                height: '100%',
                marginBottom: '45px',
                textAlign: 'right',
              }}
            >
              <h2
                className="boldText"
                style={{
                  fontSize: '28px',
                  marginBottom: '10px',
                  marginRight: '28px',
                  marginTop: '30px',
                }}
              >
                Forget Complexity
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>No UVs, no maps, no tiling</strong> — everything is
                generated procedurally to free you from limitations of
                traditional materials.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Infinite precision</strong> — math-based materials ensure
                unlimited resolution for every generated texture.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Easy to use</strong>, thanks to carefully defined
                parameters while ensuring maximum control.
              </p>
            </div>
          </div>
          <div className="shaderCard shadersForget">
            {/* If you have an image, include it here with alt text for SEO and accessibility */}
            {/* Example:
            <img src="/images/shader-forget-complexity.jpg" alt="Simplify with Procedural Shaders" className="shaderImage" />
            */}
          </div>
        </div>
      </div>

      <div className="homeContainerMain" style={{ marginTop: '15px' }}>
        <div className="faqContainerCardsContent">
          <div className="shaderCard shadersChoice">
            {/* If you have an image, include it here with alt text for SEO and accessibility */}
            {/* Example:
            <img src="/images/shader-industry-choice.jpg" alt="The Industry’s Choice for Shaders" className="shaderImage" />
            */}
          </div>

          <div className="toolInfoCard">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                height: '100%',
                marginBottom: '45px',
              }}
            >
              <h2
                className="boldText"
                style={{
                  fontSize: '28px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '30px',
                }}
              >
                The Industry's Choice
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Trusted by the industry.</strong> Already utilized by most
                major car design studios all over the world.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Next Gen.</strong> Taking all of the learning from the
                first version, this library was built from the ground up to reach
                the next level of quality.
              </p>
              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                }}
              >
                <strong>Complete package</strong>, install the addon in one click
                and have fun!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="downloadContainerThin" style={{ marginTop: '2px' }}>
        <div className="downloadContainerThinContent">
          <h2
            className="mediumText"
            style={{
              fontSize: '48px',
              color: 'white',
              marginBottom: '15px',
            }}
          >
            Download For Free
          </h2>
          <a href="https://udinbv.gumroad.com/l/shaders">
            <button
              className="genericHomeButton"
              style={{ width: '150px', fontSize: '16px' }}
              aria-label="Download Blender Shaders"
            >
              Download
            </button>
          </a>
        </div>
      </div>

      <Footer /> {/* Use Footer component */}
    </div>
  );
}

export default BlenderShaders;