import React from 'react';
import './Home.css';
import './ToolsInfo.css';
import NavBar from './NavBar'; // Import NavBar component
import Footer from './Footer'; // Import Footer component
function ToolsInfo() {

    return (
        <div className="Home">
            <NavBar /> {/* Use NavBar component */}
            <div className="homeContainerMain">
                <div className="toolsMainContent">
                    <h1 className="boldText" style={{ fontSize: '64px', color: 'white', marginBottom: '10px' }}>
                        Tools
                    </h1>
                </div>
            </div>
            
            <div className="homeContainerMain">
                <div className="faqContainerCardsContent">
                    <div className="toolCard optic">
                        <div>
                        </div>
                    </div>

                    <div className="toolInfoCard">
                        <div >
                            <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '10px', marginLeft: '28px', marginTop: '30px' }}>
                                Optic</h1>
                            
                            <h3 className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                Optic is a AI-powered design tool that assists you in your design workflow. Iterate faster and create high quality visuals in seconds. Get a two weeks trial for free.
                            </h3>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <a href="/optic">
                                <button className="opticButton"style={{ marginLeft: '28px', marginBottom: '30px', marginRight: '-10px', marginTop: '10px' }}>
                                    Open App
                                </button>
                                </a>
                                <a href="/optic-tool">
                                <button className="toolsButton" style={{ marginLeft: '28px', marginBottom: '28px' }}>
                                    Learn More
                                </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="homeTitleContainer">
                <div className="homeTitle">Blender</div>
            </div>

            <div className="homeContainerMain" style={{ marginTop: '15px'}}>
            <div className="faqContainerCardsContent">
                    <div className="toolCard shaders">
                    </div>

                    <div className="toolInfoCard">
                        <div>
                            <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '10px', marginLeft: '28px', marginTop: '30px' }}>
                                Udin Shaders</h1>
                            
                            <h3 className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                The essential Blender shader collection for designers. Give life to your models through high quality, easy-to-use materials. Download for free now.
                            </h3>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <a href="https://udinbv.gumroad.com/l/shaders">
                                <button className="opticButton"style={{ marginLeft: '28px', marginBottom: '30px', marginRight: '-10px', marginTop: '10px' }}>
                                    Download
                                </button>
                                </a>
                                <a href="/blender-shaders">
                                <button className="toolsButton" style={{ marginLeft: '28px', marginBottom: '28px' }}>
                                    Learn More
                                </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="homeContainerMain" style={{ marginTop: '15px'}}>
                <div className="faqContainerCardsContent">
                    <div className="toolCard marking-menus">
                        <div>
                        </div>
                    </div>

                    <div className="toolInfoCard">
                        <div>
                            <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '10px', marginLeft: '28px', marginTop: '30px' }}>
                                Marking Menus</h1>
                            
                            <h3 className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                The fastest way to access your favorite Blender tools. Speed up your modelling workflow through intuitive pie menus directly in the viewport.
                            </h3>
                            <a href="https://odilon.gumroad.com/l/odi_marking_menus">
                            <button className="toolsButton"  style={{ marginLeft: '28px', marginBottom: '28px' }}>
                                Download
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="homeContainerMain" style={{ marginTop: '15px'}}>
                <div className="faqContainerCardsContent">
                    <div className="toolCard import-helper">
                        <div>
                        </div>
                    </div>

                    <div className="toolInfoCard">
                        <div>
                            <h1 className="boldText" style={{ fontSize: '28px', marginBottom: '10px', marginLeft: '28px', marginTop: '30px' }}>
                                FBX Import Helper</h1>
                            
                            <h3 className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                The easiest way to organize your automotive CAD data in Blender. The addon automatically merges your imported objects by groups or shader and organizes them.
                            </h3>
                            <a href="https://udinbv.gumroad.com/l/alias-fbx-import-helper">
                            <button className="toolsButton" style={{ marginLeft: '28px', marginBottom: '28px' }}>
                                Download
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>

            
            <Footer /> {/* Use Footer component */}
        </div>
    );
}

export default ToolsInfo;