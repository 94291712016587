import React, { useState, useEffect, useRef } from 'react';

function ComparisonSlider({ beforeImage, afterImage }) {
  const [value, setValue] = useState(100);
  const [clipTransition, setClipTransition] = useState('clip-path 1s ease-in-out');
  const [leftTransition, setLeftTransition] = useState('left 1s ease-in-out');
  const sliderRef = useRef(null);
  const animationRef = useRef(null); // To keep track of the animation

  useEffect(() => {
    // Intersection Observer to detect when the slider is in view
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start the initial animation
          animateSlider();
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
      // Clear any running animation
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);

  const animateSlider = () => {
    let currentValue = 100;
    const endValue = 25;
    const duration = 1000; // Total duration of the animation in ms
    const totalSteps = currentValue - endValue; // Number of steps
    const stepTime = duration / totalSteps; // Time per step in ms

    const step = () => {
      currentValue -= 1;
      setValue(currentValue);

      if (currentValue > endValue) {
        animationRef.current = setTimeout(step, stepTime);
      } else {
        // Initial animation finished
        // Wait 1 second before changing transition durations
        setTimeout(() => {
          setClipTransition('clip-path 0s ease-in-out');
          setLeftTransition('left 0s ease-in-out');
        }, 1000);
      }
    };

    step();
  };

  const handleInput = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  return (
    <div className="comparison-slider" ref={sliderRef}>
      <div
        className="comparison-image comparison-image-before"
        style={{ backgroundImage: `url(${beforeImage})` }}
      ></div>
      <div
        className="comparison-image comparison-image-after"
        style={{
          backgroundImage: `url(${afterImage})`,
          clipPath: `inset(0 ${100 - value}% 0 0)`,
          transition: clipTransition,
        }}
      ></div>
      <input
        type="range"
        min="0"
        max="100"
        className="slider"
        value={value}
        onChange={handleInput}
      />
      <div
        className="slider-handle"
        style={{
          left: `${value}%`,
          transition: leftTransition,
        }}
      ></div>
    </div>
  );
}

export default ComparisonSlider;