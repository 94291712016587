import React from 'react';

import './TermsAndConditions.css'; // Assuming you are keeping your CSS in a separate file



const TermsAndConditions = () => {

  return (

    <div className="terms">

      <div className="termsHeaderContainer">
        <a href="https://udinbv.com" target="_blank" rel="noopener noreferrer">
        <div className="logoBlack">
        </div>
        </a>
      </div>

      <div className="termsMainContainer">

        <div className="termsContentContainer">

          <h1 className='termsTitle'>Terms of Service</h1>

          <div className="horizontal-line3" style={{marginTop:'20px', marginBottom: '20px'}}></div>

          <h2 className='sectionTitle'>1. Introduction</h2>

          <p className='termsBreadText'>
            Welcome to Udin Optic ("we", "our", or "us"). By using our platform, you agree to comply with and be bound by these Terms of Service ("Terms"). Please read them carefully. If you do not agree to these Terms, you should not use our platform.
          </p>

          <h2 className='sectionTitle'>2. Eligibility</h2>

          <p className='termsBreadText'>
            By using this platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into and abide by these Terms.
          </p>

          <h2 className='sectionTitle'>3. User Responsibilities</h2>

          <p className='termsBreadText'>
            <strong>3.1. Image Verification</strong><br/>
            You are solely responsible for ensuring that any images you upload to train or generate images with comply with all applicable intellectual property laws, including but not limited to copyright, trademark, and privacy laws.
          </p>
          <p className='termsBreadText'>

            <strong>3.2. Prohibited Content</strong><br/>
            You agree not to upload, share, or generate content that:
            <ul>
              <li>Violates any intellectual property rights, including copyright and trademark laws.</li>
              <li>Contains any personal data without the explicit consent of the individuals concerned.</li>
              <li>Is harmful, defamatory, obscene, offensive, or promotes illegal activities.</li>
            </ul>
          </p>

          <h2 className='sectionTitle'>4. Compliance with GDPR and CCPA</h2>

          <p className='termsBreadText'>
            <strong>4.1 Data Collection and Usage</strong><br/>
            We collect personal data for the purpose of providing and improving our services. By using our platform, you consent to the collection, use, and storage of your data in accordance with our Privacy Policy.
          </p>

          <p className='termsBreadText'>

            <strong>4.2 User Rights</strong><br/>

            <ul>
              <li><strong>Access and Rectification</strong>: You have the right to access your data and request corrections for any inaccuracies.</li>
              <li><strong>Erasure</strong>: You can request the deletion of your personal data, subject to certain exceptions.</li>
            </ul>
          </p>

          <p className='termsBreadText'>
            <strong>4.3 Data Security</strong><br/>
            We implement reasonable technical and organizational measures to protect your personal data against unauthorized access, loss, or breach.
          </p>

          <h2 className='sectionTitle'>5. Intellectual Property</h2>

          <p className='termsBreadText'>
            <strong>5.1. Ownership</strong><br/>
            All content generated on our platform is owned by the respective users who create it, provided it does not infringe upon third-party rights.
          </p>

          <p className='termsBreadText'>
            <strong>5.2. Licensing</strong><br/>
            By uploading content, you grant us a non-exclusive, worldwide, royalty-free license to use, store, display, and reproduce the content for purposes of operating the platform and improving user experience.
          </p>

          <h2 className='sectionTitle'>6. Limitation of Liability</h2>

          <p className='termsBreadText'>
          To the maximum extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our platform. Our total liability for any claim arising out of or relating to these Terms shall not exceed the amount you have paid us, if any, for use of the platform.
          </p>

          <h2 className='sectionTitle'>7. Indemnification</h2>

          <p className='termsBreadText'>
            You agree to indemnify, defend, and hold harmless Udin Optic and its affiliates from any claims, losses, damages, liabilities, costs, and expenses arising out of your use of the platform, your violation of these Terms, or your infringement of any third-party rights.
          </p>

          <h2 className='sectionTitle'>8. Governing Law</h2>
          <p className='termsBreadText'>
          These Terms shall be governed by and construed in accordance with the laws of the Netherlands, without regard to its conflict of law principles.
          </p>

          <h2 className='sectionTitle'>9. Changes to Terms</h2>

          <p className='termsBreadText'>
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of the platform following the posting of revised Terms means that you accept and agree to the changes.
          </p>

          <h2 className='sectionTitle'>10. Contact Us</h2>

          <p className='termsBreadText'>
            If you have any questions about these Terms, please contact us at udin.companyinfo@gmail.com.
          </p>

          <div className="horizontal-line3"></div>

          <h2 className='sectionTitle'>Acceptance of Terms</h2>

          <p className='termsBreadText'>
            By using Udin Optic, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
          </p>

        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;