import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './login.css';
import { app, auth, googleProvider } from './firebase-config';
import { signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const Login = ({ onAuthComplete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);

  // Fetch the route to navigate back to from the location state, or default to "/"
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    // Handle redirect results (if any)
    // This ensures that if the user is redirected back from OAuth, we handle the result
    // Note: You may need to implement getRedirectResult if using signInWithRedirect
  }, []);

  const handleSocialLogin = async (provider) => {
    try {
      // Try to sign in using the popup method
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const token = await user.getIdToken();

      if (token) {
        localStorage.setItem('authToken', token);
        if (onAuthComplete) {
          onAuthComplete(token);
        }
      }

      // Save user to Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          lastLogin: new Date(),
        },
        { merge: true }
      );

      // Redirect to the desired page
      navigate(from, { replace: true });
    } catch (error) {
      if (
        error.code === 'auth/cancelled-popup-request' ||
        error.code === 'auth/popup-blocked' ||
        error.code === 'auth/popup-closed-by-user'
      ) {
        // If popup is blocked or canceled, fallback to redirect method
        signInWithRedirect(auth, provider);
      } else {
        alert(error.message);
      }
    }
  };

  return (
    <div className="Login">
      <Helmet>
        <title>Login - UDIN</title>
        <meta
          name="description"
          content="Log in to your UDIN account to access the Optic AI Design Tool."
        />
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Login - UDIN" />
        <meta
          property="og:description"
          content="Log in to your UDIN account to access the Optic AI Design Tool."
        />
        <meta property="og:url" content="https://udinbv.com/login" />
        <meta property="og:type" content="website" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Login - UDIN" />
        <meta
          name="twitter:description"
          content="Log in to your UDIN account to access the Optic AI Design Tool."
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* If you have a representative image, include og:image and twitter:image */}
      </Helmet>

      <div className="secondaryContainer">
        <div className="logoBlack">
          {/* If you have a logo image, include it here with alt text */}
          {/* Example:
          <img src="/path/to/logo.png" alt="UDIN Logo" />
          */}
        </div>
      </div>

      <main className="centerContainer">
        <div className="loginContainer">
          <h1
            className="loginTitle"
            style={{ marginBottom: '5px', marginLeft: '5px' }}
          >
            Login
          </h1>

          <div
            style={{ display: 'inline-block', marginTop: '13px', marginBottom: '23px' }}
            className="horizontal-line2"
            aria-hidden="true"
          ></div>

          <button
            className="googleLoginButton"
            onClick={() => handleSocialLogin(googleProvider)}
            aria-label="Continue with Google"
            style={{paddingBottom: '20px'}}
          >
            <div style={{ marginLeft: '5px' }}>
              <div style={{ marginBottom: '14.5px' }}>
                <span className="googleText">Continue with Google</span>
              </div>
              <div
                className="googleIcon"
                style={{ marginLeft: '25px', marginTop: '-35px', marginBottom: '-15px' }}
              ></div>
            </div>
          </button>
        </div>
      </main>

      <div className="secondaryContainer"></div>
    </div>
  );
};

export default Login;