import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import './ToolsInfo.css'; // Reuse existing styles for consistency
import './Contact.css'; // Import the new Contact.css
import NavBar from './NavBar';
import Footer from './Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation checks
    if (!formData.name) {
      alert('Please enter your name.');
      return;
    }
    if (!formData.email) {
      alert('Please enter your email.');
      return;
    }
    if (!formData.message) {
      alert('Please enter your message.');
      return;
    }

    fetch('/.netlify/functions/send-email-submission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true); // Set submission status to true
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error submitting the form. Please try again later.');
      });
  };

  return (
    <div className="Home">
      <Helmet>
        <title>Contact Us - UDIN</title>
        <meta
          name="description"
          content="Get in touch with us at UDIN. You can contact us about anything, whether it's Optic AI, Blender Shaders or you just want to say hi. We will get back to you as soon as possible!"
        />
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Contact Us - UDIN" />
        <meta
          property="og:description"
            content="Get in touch with us at UDIN. You can contact us about anything, whether it's Optic, Blender Shaders or you just want to say hi. We will get back to you as soon as possible!"
        />
        <meta property="og:url" content="https://www.yourdomain.com/contact" />
        <meta property="og:type" content="website" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Contact Us - UDIN" />
        <meta
          name="twitter:description"
          content="Get in touch with us at UDIN. You can contact us about anything, whether it's Optic, Blender Shaders or you just want to say hi. We will get back to you as soon as possible!"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <NavBar />
      {/* <main> */}
        <div className="homeContainerMain">
          <div className="contactMainContent">
            <h1
              className="boldText"
              style={{ fontSize: '64px', color: 'white', marginBottom: '10px' }}
            >
              Contact Us
            </h1>
          </div>
        </div>

        <div className="homeContainerMain">
          <div className="faqContainerCardsContent">
            <form className="toolInfoCard" onSubmit={handleSubmit}>
              <div style={{ marginTop: '30px', marginLeft: '30px', marginRight: '8px' }}>
                <label htmlFor="name" className="hiddenLabel">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="inputField"
                  required
                />
                <label htmlFor="email" className="hiddenLabel">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="inputField"
                  required
                />
                <label htmlFor="message" className="hiddenLabel">
                  Your Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="inputField textAreaField"
                  required
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {isSubmitted ? (
                    <span
                      className="successMessage"
                      style={{
                        fontSize: '16px',
                        color: '#28a745',
                        marginBottom: '28px',
                      }}
                    >
                      Message Sent! Thank you!
                    </span>
                  ) : (
                    <button
                      type="submit"
                      className="opticButton"
                      style={{ padding: '10px 25px', marginBottom: '28px' }}
                    >
                      Send Message
                    </button>
                  )}
                  <span className="normalText" style={{ fontSize: '16px', marginBottom: '20px', marginLeft: '-25px' }}>
                    OR
                  </span>
                  <a
                    href="mailto:info@udinbv.com"
                    className="normalText"
                    style={{
                      fontSize: '16px',
                      marginBottom: '40px',
                      marginLeft: '-25px',
                      color: '#000',
                      textDecoration: 'underline',
                    }}
                  >
                    info@udinbv.com
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      {/* </main> */}
      <Footer />
    </div>
  );
}

export default Contact;