import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';
import NavBar from './NavBar'; // Reuse NavBar component
import Footer from './Footer'; // Reuse Footer component
import './Gallery.css';

function Gallery() {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // State for selected image
  const [selectedImageAuthor, setSelectedImageAuthor] = useState(''); // State for selected image author

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const listRef = ref(storage, 'public/gallery/'); // Adjust the path to your storage folder

      try {
        const res = await listAll(listRef);
        const urls = await Promise.all(
          res.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return {
              url,
              author: extractAuthorFromUrl(item.fullPath),
            };
          })
        );

        const shuffledUrls = shuffleArray(urls); // Shuffle the images
        setImages(shuffledUrls);
      } catch (error) {
        console.error('Error fetching images: ', error);
      }
    };

    fetchImages();
  }, []);

  // Shuffle function using Fisher-Yates algorithm
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const breakpointColumnsObj = {
    default: 4, // Number of columns for default screen size
    1100: 3, // Number of columns for screen width 1100px and above
    700: 2, // Number of columns for screen width 700px and above
    500: 1, // Number of columns for screen width 500px and above
  };

  const extractAuthorFromUrl = (path) => {
    // Get the filename from the full path
    const filename = path.replace(/^.*[\\/]/, '');
    // Remove file extension
    const nameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
    // Remove the trailing image number (e.g., _1)
    const nameWithoutImageNumber = nameWithoutExtension.replace(/_\d+$/, '');
    // Replace underscores with spaces
    const authorName = nameWithoutImageNumber.replace(/_/g, ' ');
    return authorName;
  };

  const handleImageClick = (image) => {
    setSelectedImage(image.url); // Set the selected image
    setSelectedImageAuthor(image.author); // Set the selected image author
  };

  const closeOverlay = () => {
    setSelectedImage(null); // Close the overlay
  };

  return (
    <div className={`Gallery ${selectedImage ? 'blurred' : ''}`}>
      <Helmet>
        <title>UDIN</title>
        <meta
          name="description"
          content="Discover what people are creating with Optic AI Design Tool. Explore our gallery showcasing stunning designs created by our community."
        />
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Gallery - Optic" />
        <meta
          property="og:description"
          content="Discover what people are creating with Optic AI Design Tool. Explore our gallery showcasing stunning designs created by our community."
        />
        <meta property="og:url" content="https://udinbv.com/gallery" />
        <meta property="og:type" content="website" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Gallery - Optic" />
        <meta
          name="twitter:description"
          content="Discover what people are creating with Optic AI Design Tool. Explore our gallery showcasing stunning designs created by our community."
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add og:image and twitter:image if you have a representative image */}
      </Helmet>
      <div className="blurredContent">
        <NavBar /> {/* Use NavBar component */}
        <main>
          <div className="homeContainerMain">
            <header className="galleryMainContent" style={{ marginBottom: '7px' }}>
              <h1
                className="boldText"
                style={{ fontSize: '64px', color: 'white', marginBottom: '-15px' }}
              >
                Gallery
              </h1>
              <p
                className="mediumText"
                style={{ fontSize: '24px', color: 'white', marginBottom: '15px' }}
              >
                Discover what people create with Optic
              </p>
            </header>
          </div>
          <section className="galleryContainerMain">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className="galleryItem"
                  onClick={() => handleImageClick(image)}
                >
                  <img
                    src={image.url}
                    alt={`Design by ${image.author}`}
                    className="galleryImage"
                  />
                </div>
              ))}
            </Masonry>
          </section>
        </main>
        <Footer /> {/* Use Footer component */}
      </div>
      {selectedImage && (
        <div className="overlay" onClick={closeOverlay}>
          <button
            className="closeImage"
            onClick={(e) => {
              e.stopPropagation();
              closeOverlay();
            }}
            aria-label="Close Image"
          >
            <div className="closeIconGallery"></div>
          </button>
          <img
            src={selectedImage}
            alt={`Selected Design by ${selectedImageAuthor}`}
            className="fullImage"
            onClick={(e) => e.stopPropagation()}
          />

          <div
            className="normalText"
            style={{
              color: 'white',
              fontSize: '14px',
              marginTop: '10px',
              textAlign: 'center',
              opacity: '65%',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            Author: {selectedImageAuthor}
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;