import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Ensure this file contains the necessary styles

function Footer() {
  return (
    <footer className="footerContainer">
      <div className="footerBar">
        <div className="companyDetailsContainer">
          <Link to="/" className="udinLogo">
            {/* If you have a logo image, include it here with an alt attribute for SEO and accessibility */}
            {/* Example: <img src="/path/to/logo.png" alt="UDIN Logo" /> */}
          </Link>
          <span
            className="normalText"
            style={{
              marginLeft: '25px',
              marginBottom: '20px',
              fontSize: '14px',
              opacity: '0.5',
            }}
          >
            <a
              href="mailto:info@udinbv.com"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              info@udinbv.com
            </a>
          </span>
        </div>
        <nav className="navigationContainer" aria-label="Footer Navigation">
          <div className="footerLinks hide-on-mobile">
            <span className="footerLinkTitle">Company</span>
            <Link className="footerLink" to="/tools">
              Tools
            </Link>
            <Link className="footerLink" to="/about-us">
              About Us
            </Link>
            <Link className="footerLink" to="/contact">
              Contact
            </Link>
          </div>
          <div className="footerLinks hide-on-mobile">
            <span className="footerLinkTitle">Optic</span>
            <Link className="footerLink" to="/optic-tool">
              Features
            </Link>
            <Link className="footerLink" to="/pricing">
              Pricing
            </Link>
            <Link className="footerLink" to="/gallery">
              Gallery
            </Link>
          </div>
          <div className="footerLinks hide-on-mobile">
            <span className="footerLinkTitle">Socials</span>
            <a
              className="footerLink"
              href="https://www.instagram.com/udin.bv"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              className="footerLink"
              href="https://www.linkedin.com/company/udin-bv"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="footerLink"
              href="https://www.youtube.com/@udin_official/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          </div>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;