import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import './Pricing.css';
import NavBar from './NavBar'; // Import NavBar component
import Footer from './Footer'; // Import Footer component
import PricingPlans from './PricingPlan';

function Pricing() {
  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <div className="Home">
      <Helmet>
        <title>Pricing - UDIN</title>
        <meta
          name="description"
          content="Explore our pricing options for Optic AI Design Tool and start creating today."
        />
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content="Pricing - UDIN" />
        <meta
          property="og:description"
          content="Explore our pricing options for Optic AI Design Tool and start creating today."
        />
        <meta property="og:url" content="https://yourdomain.com/pricing" />
        <meta property="og:type" content="website" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Pricing - UDIN" />
        <meta
          name="twitter:description"
          content="Explore our pricing options for Optic AI Design Tool and start creating today."
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add og:image and twitter:image if available */}
      </Helmet>
      <NavBar /> {/* Use NavBar component */}
      <div className="homeContainerMain">
        <div className="pricingMainContent">
          <h1
            className="boldText"
            style={{
              fontSize: '64px',
              color: 'white',
              marginBottom: '-15px',
            }}
          >
            Pricing
          </h1>
          <p
            className="mediumText"
            style={{
              fontSize: '24px',
              color: 'white',
              marginBottom: '15px',
            }}
          >
            Choose the plan that's right for you
          </p>
        </div>
      </div>

      <PricingPlans isMonthly={isMonthly} setIsMonthly={setIsMonthly} />

      <div className="homeTitleContainer">
        <div className="homeTitle" style={{ marginRight: '45px' }}>
          Frequently Asked Questions
        </div>
      </div>

      <div className="homeContainerMain">
        <div className="faqContainerCardsContent">
          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                What payment methods can I use?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                We accept credit card, Apple Pay, Google Pay, PayPal, Alipay and Link.
              </p>
            </div>
          </div>

          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Do I own the images I create with Optic?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                Yes, all content generated on our platform is owned by the respective users who create it, provided it does not infringe upon third-party rights.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Repeating FAQ cards as per your requirement */}
      <div className="homeContainerMain" style={{ marginTop: '15px' }}>
        <div className="faqContainerCardsContent">
          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Can other people see my generations?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                No, all of the settings and images are private to your account. Nobody else can see nor access them.
              </p>
            </div>
          </div>

          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Is Optic trained on user-generated content?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                No, we don't use nor store any subscriber generated images. We only save data that is essential to the user experience such as presets and Lenses.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Repeat as needed for testing */}
      <div className="homeContainerMain" style={{ marginTop: '15px' }}>
        <div className="faqContainerCardsContent">
          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                How do I cancel my subscription?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                You can cancel your subscription anytime by going to your subscription settings inside Optic. If you have any problems, contact us!
              </p>
            </div>
          </div>

          <div className="faqCard">
            <div>
              <h2
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                How can I request access for my company?
              </h2>

              <p
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                Please contact us at info@udinbv.com or use the contact form on our website.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer /> {/* Use Footer component */}
    </div>
  );
}

export default Pricing;