import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Home.css';
import './Pricing.css';
import './About.css';
import NavBar from './NavBar';
import Footer from './Footer';

function About() {
  return (
    <div className="Home">
      <Helmet>
        <title>About Us - UDIN</title>
        <meta
          name="description"
          content="Learn more about UDIN, our mission, our team, and why we're developing tools for creatives around the world."
        />
      </Helmet>
      <NavBar />
      <div className="homeContainerMain">
        <div className="aboutMainContent">
          <h1
            className="boldText"
            style={{ fontSize: '64px', color: 'white', marginBottom: '10px' }}
          >
            About Us
          </h1>
        </div>
      </div>

      <div className="homeContainerMain" style={{ marginBottom: '15px' }}>
        <div className="homeContainerCardsContent">
          <div className="aboutCard esa">
            <div>
              <h1
                className="normalText"
                style={{
                  fontSize: '20px',
                  marginBottom: '5px',
                  marginLeft: '28px',
                  marginTop: '25px',
                  textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
                }}
              >
                Co-Founder
              </h1>
              <h2
                className="boldText"
                style={{
                  fontSize: '28px',
                  marginLeft: '28px',
                  marginBottom: '10px',
                  textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
                }}
              >
                Esa Mustonen
              </h2>

              <h2
              className="normalText"
              style={{
                fontSize: '16px',
                marginLeft: '28px',
                marginRight: '28px',
                marginBottom: '23px',
                textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
              }}
            >
              Prev. Exp: Designer at Koenigsegg
            </h2>
            </div>
          </div>

          <div className="aboutCard odi">
            <div>
              <h1
                className="normalText"
                style={{
                  fontSize: '20px',
                  marginBottom: '5px',
                  marginLeft: '28px',
                  marginTop: '25px',
                  textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
                }}
              >
                Co-Founder
              </h1>
              <h2
                className="boldText"
                style={{
                  fontSize: '28px',
                  marginLeft: '28px',
                  marginBottom: '10px',
                  textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
                }}
              >
                Odilon Loiez
              </h2>

              <h2
              className="normalText"
              style={{
                fontSize: '16px',
                marginLeft: '28px',
                marginRight: '28px',
                marginBottom: '23px',
                textShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
              }}
            >
              Prev. Exp: Designer at Koenigsegg
            </h2>
            </div>
          </div>

          <div
            className="aboutCard founders"
            style={{ alignItems: 'flex', justifyContent: 'flex-end' }}
          >
            <div
              className="homeTitle"
              style={{ color: 'white', marginRight: '15px', marginLeft: '28px', marginBottom: '7px' }}
            >
              Founders
            </div>

            <h2
              className="mediumText"
              style={{
                fontSize: '16px',
                marginLeft: '28px',
                marginRight: '28px',
                marginBottom: '23px',
              }}
            >
              We are automotive designers. Leveraging our experience, we optimize design workflows with the innovative tools we develop.
            </h2>
          </div>
        </div>
      </div>

      <div className="homeTitleContainer">
        <div className="homeTitle" style={{ marginRight: '45px' }}>
          Our Company
        </div>
      </div>

      <div className="homeContainerMain" style={{ marginBottom: '15px' }}>
        <div className="faqContainerCardsContent">
          <div className="faqCard">
            <div>
              <h1
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Core Principle
              </h1>

              <h3
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                We approach challenges in a systematic way. For us, getting a deep understanding of the context and variables is essential to create useful and meaningful solutions. We tackle problems we face as professionals, which helps us set the highest standards for the solution.
              </h3>
            </div>
          </div>

          <div className="faqCard">
            <div>
              <h1
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Culture
              </h1>

              <h3
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                We don't care about conventional ways or obsession with flawlessness. We care about the rate of improvement and the quality of output. None of this should come at the cost of learning and having fun.
              </h3>
            </div>
          </div>

          
        </div>
      </div>

      <div className="homeContainerMain">
        <div className="faqContainerCardsContent">
          <div className="faqCard">
            <div>
              <h1
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Contact Us
              </h1>

              <h3
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                We'd love to hear from you! Reach out to us at{' '}
                <Link to="/contact" style={{ color: '#000', textDecoration: 'underline' }}>
                  our contact page
                </Link>{' '}
                or email us at info@udinbv.com.
              </h3>
            </div>
          </div>

          <div className="faqCard">
            <div>
              <h1
                className="boldText"
                style={{
                  fontSize: '20px',
                  marginBottom: '10px',
                  marginLeft: '28px',
                  marginTop: '25px',
                }}
              >
                Where are we based?
              </h1>

              <h3
                className="normalText"
                style={{
                  fontSize: '16px',
                  marginLeft: '28px',
                  marginRight: '28px',
                  marginBottom: '23px',
                }}
              >
                We are an international team based in Rotterdam, The Netherlands.
              </h3>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;